<template>
	<div class="page">
		<div class="block30"></div>
		<div class="back_typebox w1200" @click="sysgoback()">
			<img class="icon" src="../assets/static/leftgo.png"/>
			<div class="text fontsize16">返回上一页</div>
		</div>
		<div class="block20"></div>
		<div class="huaxue_info_detail relative w1200" v-if="dataobj">
			<div class="titlediv">
				<div class="title fontsize24 text-center">{{dataobj.title}}</div>
				<div class="timer fontsize14 text-center">发布时间：{{dataobj.releaseTime || dataobj.createDate}}</div>
				<div class="videodiv" v-if="dataobj.videoUrl">
					<video :src="dataobj.videoUrl" class="video" controls="controls">您的浏览器不支持视频播放</video>
				</div>
			</div>
			<div class="data_con">
				<div class="fontsize14" v-html="dataobj.contents"></div>
			</div>
			<div class="block20" v-if="dataobj.fileInfoVo&&dataobj.fileInfoVo.fileUrl"></div>
			<div class="filebox fontsize16" v-if="dataobj.fileInfoVo&&dataobj.fileInfoVo.fileUrl">
				<div class="imgicon"><img src="../assets/static/Frame08.png"/></div>
				<div class="text">附件：</div>
				<a class="a" :href="dataobj.fileInfoVo.fileUrl" target="_blank">{{dataobj.fileInfoVo.fileName}}</a>
			</div>
			<div class="block30"></div>
			<!-- <div class="huaxue_sx_page clear">
				<div class="sx_item fl">
					<div class="title fontsize14" v-html="'<&nbsp;上一篇'"></div>
					<div class="desc fontsize18" v-if="dataobj.last" @click="clickarticleDetail(dataobj.last.id)">{{dataobj.last.title}}</div>
					<div class="desc_no fontsize18" v-else>已经没有了</div>
				</div>
				<div class="sx_item fr" style="text-align: right;">
					<div class="title fontsize14" v-html="'下一篇&nbsp;>'"></div>
					<div class="desc fontsize18" v-if="dataobj.next" @click="clickarticleDetail(dataobj.next.id)">{{dataobj.next.title}}</div>
					<div class="desc_no fontsize18" v-else>已经没有了</div>
				</div>
			</div> -->
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			uid:0,//详情
			dataobj:null,
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$alert('参数丢失，请返回重试！', '提示', {
			    confirmButtonText: '好的',
			    callback: action => {
					this.sysgoback()
			    }
			});
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
			this.$router.go(0);
		},
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				id:this.uid
			};
			this.$http.post('app/article/getById', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataobj = dataobj
					}else{
						_this.$alert('内容记录不存在，请返回重试！', '提示', {
						    confirmButtonText: '好的',
						    callback: action => {
								_this.sysgoback()
						    }
						});
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss" scoped>
.back_typebox{
	height: 38px;
	display: flex;
	align-items: center;
	cursor: pointer;
	.icon{
		width: 28px;
		height: 28px;
		margin-right: 8px;
	}
}
.huaxue_info_detail{
	box-shadow: 0px 5px 24px 1px rgba(0, 0, 0, 0.08);
	padding: 12px 32px;
	box-sizing: border-box;
	border-radius: 8px;
	overflow: hidden;
	.titlediv{
		padding: 20px 0;
		border-bottom: 1px solid #E0E0E0;
		.title{
			line-height: 28px;
			font-weight: bold;
			color: #000000;
		}
		.timer{
			margin-top: 12px;
			font-weight: 400;
			color: #999999;
		}
		.videodiv{
			width: 100%;
			margin: 18px auto 0;
			height: auto;
			video{
				width: 100%;
				height: 100%;
			}
		}
	}
    .data_con{
		padding: 20px 0;
		font-weight: 400;
		color: #666666;
		line-height: 25px;
	}
	.filebox{
		display: flex;
		align-items: center;
		.imgicon{
			width: 28px;
			height: 28px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.text{
			font-weight: 400;
			color: #666666;
		}
		.a{
			font-weight: 400;
			color: #247FE4;
		}
	}
	.huaxue_sx_page{
		width: 100%;
		border-top: 1px solid #E0E0E0;
		padding: 20px 0;
		.sx_item{
			width: 35%;
			.title{
				font-weight: 400;
				color: #999999;
			}
			.desc{
				margin-top: 8px;
				font-weight: 400;
				color: #333333;
				cursor: pointer;
			}
			.desc_no{
				margin-top: 8px;
				font-weight: 400;
				color: #333333;
			}
			.desc:hover{
				color: #247FE4;
			}
		}
	}
}
</style>
